const loadingIcons = document.querySelectorAll('.p-loading__icon');
const loading = document.querySelector('.p-loading');

window.addEventListener('DOMContentLoaded', () => {
    setTimeout(function() {
        loadingIcons.forEach(function(loadingIcon) {
            loadingIcon.classList.add('is-active');
        });
    }, 500);
    setTimeout(function() {
        loading.classList.remove('is-active');
    }, 4000);
});

// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
// 日付表示
// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
// 今日の日付を取得できるnew Dateを格納
const today = new Date();

// 年・月・日・曜日を取得
const month = today.getMonth() + 1;
const date = today.getDate();
const day = today.getDay();
const weekday = ["SUN","MON","TUE","WED","THU","FRI","SAT"];

// console.log(`${month}.${date}`);
// console.log(weekday[today.getDay()]);

document.querySelector('.p-fv__date .date').innerHTML += `${month}.${date}`;
document.querySelector('.p-fv__date .day').innerHTML += weekday[today.getDay()];


// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
// ハンバーガーメニュー
// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
document.addEventListener('DOMContentLoaded', function() {
    function addActive() {
        document.querySelector('.p-hamburger').classList.toggle('is-active')
        document.querySelector('.p-hamburger__line1').classList.toggle('is-active')
        document.querySelector('.p-hamburger__line2').classList.toggle('is-active')
        document.querySelector('.p-hamburger__line3').classList.toggle('is-active')
        document.querySelector('.p-hamburger__nav').classList.toggle('is-active')
        document.querySelector('body').classList.toggle('fixed');
        document.querySelector('.p-fv__button').classList.toggle('is-hidden');
    }
    document.querySelector('.p-hamburger').addEventListener('click', function() {
        addActive();
    });
})

const hamEls = document.querySelectorAll('.p-hamburger__close');
hamEls.forEach(function(hamEl) {
    hamEl.addEventListener('click', function() {
        document.querySelector('.p-hamburger').classList.remove('is-active')
        document.querySelector('.p-hamburger__line1').classList.remove('is-active')
        document.querySelector('.p-hamburger__line2').classList.remove('is-active')
        document.querySelector('.p-hamburger__line3').classList.remove('is-active')
        document.querySelector('.p-hamburger__nav').classList.remove('is-active')
        document.querySelector('body').classList.remove('fixed');
        document.querySelector('.p-fv__button').classList.remove('is-hidden');
    }) 
});

// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
// TOPに戻るボタン
// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
let scroll = new SmoothScroll('a[href*="#"]', {
    speedAsDuration: true,
    speed: 1000,
    easing: 'easeInOutQuint'
});  


const custom_anime = gsap.timeline({
    scrollTrigger: {
        trigger: ".l-column", //アニメーションが始まるトリガーとなる要素
        start: "top",
        end: '+=2000', //アニメーション開始位置から1000px固定する
        pin: true, //トリガー要素を固定する
        scrub: true, 
    }
});
let innerWidth = document.querySelector('.l-inner').offsetWidth;

// p-fv__carのx軸はマイナスにする必要があるため、-1を乗算
innerWidth = innerWidth * -1;

// innerWidthにp-fv__carの幅140pxと出す必要がある35pxで調整
let carX = innerWidth - 140 + 35;

custom_anime
.to('.p-fv__car', { x: carX, duration: 8 })
.to('.bubble1', { keyframes: [{ x: 0, display: "block", delay: 0.1}, { x: -10, display: "none", duration: 0.2 } ]}, "<")
.to('.bubble2', { keyframes: [{ x: 0, display: "block", delay: 3},  { x: -10, display: "none", duration: 0.2 } ]}, "<")
.to('.bubble3', { keyframes: [{ x: 0, display: "block", delay: 5},  { x: -10, display: "none", duration: 0.2 } ]}, "<");